@font-face {
    font-family: "icomoon";
    src: url("./icomoon.eot?dc55010b4cc541fa66cfce3a468740f5#iefix") format("embedded-opentype"),
url("./icomoon.woff2?dc55010b4cc541fa66cfce3a468740f5") format("woff2"),
url("./icomoon.woff?dc55010b4cc541fa66cfce3a468740f5") format("woff");
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: icomoon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-zoom-out:before {
    content: "\f101";
}
.icon-zoom-in:before {
    content: "\f102";
}
.icon-www-url:before {
    content: "\f103";
}
.icon-world-map:before {
    content: "\f104";
}
.icon-workspace:before {
    content: "\f105";
}
.icon-workspace-empty:before {
    content: "\f106";
}
.icon-widget:before {
    content: "\f107";
}
.icon-whitelist:before {
    content: "\f108";
}
.icon-welcome1:before {
    content: "\f109";
}
.icon-welcome:before {
    content: "\f10a";
}
.icon-weight:before {
    content: "\f10b";
}
.icon-website:before {
    content: "\f10c";
}
.icon-website-www:before {
    content: "\f10d";
}
.icon-web-url:before {
    content: "\f10e";
}
.icon-warning:before {
    content: "\f10f";
}
.icon-warning-white:before {
    content: "\f110";
}
.icon-warning-circle:before {
    content: "\f111";
}
.icon-volume:before {
    content: "\f112";
}
.icon-volume-muted:before {
    content: "\f113";
}
.icon-upload1:before {
    content: "\f114";
}
.icon-upload:before {
    content: "\f115";
}
.icon-upload-folder:before {
    content: "\f116";
}
.icon-update-product:before {
    content: "\f117";
}
.icon-update-arrows:before {
    content: "\f118";
}
.icon-unlock:before {
    content: "\f119";
}
.icon-two-arrows:before {
    content: "\f11a";
}
.icon-trophy:before {
    content: "\f11b";
}
.icon-trending_up:before {
    content: "\f11c";
}
.icon-trash:before {
    content: "\f11d";
}
.icon-translations:before {
    content: "\f11e";
}
.icon-toolbar-mention:before {
    content: "\f11f";
}
.icon-time-update:before {
    content: "\f120";
}
.icon-time-refresh:before {
    content: "\f121";
}
.icon-time-dotted:before {
    content: "\f122";
}
.icon-tick:before {
    content: "\f123";
}
.icon-tick-square:before {
    content: "\f124";
}
.icon-tick-square-filled:before {
    content: "\f125";
}
.icon-tick-square-empty:before {
    content: "\f126";
}
.icon-tick-select-all:before {
    content: "\f127";
}
.icon-tick-partial:before {
    content: "\f128";
}
.icon-tick-empty:before {
    content: "\f129";
}
.icon-tick-dark:before {
    content: "\f12a";
}
.icon-tick-black:before {
    content: "\f12b";
}
.icon-thoughts:before {
    content: "\f12c";
}
.icon-text:before {
    content: "\f12d";
}
.icon-text-editor-underlined:before {
    content: "\f12e";
}
.icon-text-editor-toolbar-format:before {
    content: "\f12f";
}
.icon-text-editor-toolbar-block:before {
    content: "\f130";
}
.icon-text-editor-text-underline:before {
    content: "\f131";
}
.icon-text-editor-text-size:before {
    content: "\f132";
}
.icon-text-editor-text-italic:before {
    content: "\f133";
}
.icon-text-editor-text-color:before {
    content: "\f134";
}
.icon-text-editor-text-bold:before {
    content: "\f135";
}
.icon-text-editor-strikethrough:before {
    content: "\f136";
}
.icon-text-editor-send:before {
    content: "\f137";
}
.icon-text-editor-right-tab:before {
    content: "\f138";
}
.icon-text-editor-remove-indent:before {
    content: "\f139";
}
.icon-text-editor-quote:before {
    content: "\f13a";
}
.icon-text-editor-numbered-list:before {
    content: "\f13b";
}
.icon-text-editor-list-unordered:before {
    content: "\f13c";
}
.icon-text-editor-list-ordered:before {
    content: "\f13d";
}
.icon-text-editor-left-tab:before {
    content: "\f13e";
}
.icon-text-editor-keyboard:before {
    content: "\f13f";
}
.icon-text-editor-heading:before {
    content: "\f140";
}
.icon-text-editor-heading-3:before {
    content: "\f141";
}
.icon-text-editor-heading-2:before {
    content: "\f142";
}
.icon-text-editor-heading-1:before {
    content: "\f143";
}
.icon-text-editor-font-size:before {
    content: "\f144";
}
.icon-text-editor-font-color:before {
    content: "\f145";
}
.icon-text-editor-emojis:before {
    content: "\f146";
}
.icon-text-editor-divider:before {
    content: "\f147";
}
.icon-text-editor-copy:before {
    content: "\f148";
}
.icon-text-editor-code:before {
    content: "\f149";
}
.icon-text-editor-check-list:before {
    content: "\f14a";
}
.icon-text-editor-camera:before {
    content: "\f14b";
}
.icon-text-editor-bullet-list:before {
    content: "\f14c";
}
.icon-text-editor-background-color:before {
    content: "\f14d";
}
.icon-text-editor-background-color-remove:before {
    content: "\f14e";
}
.icon-text-editor-align-right:before {
    content: "\f14f";
}
.icon-text-editor-align-left:before {
    content: "\f150";
}
.icon-text-editor-align-justified:before {
    content: "\f151";
}
.icon-text-editor-align-center:before {
    content: "\f152";
}
.icon-text-editor-add-relationship:before {
    content: "\f153";
}
.icon-text-editor-add-indent:before {
    content: "\f154";
}
.icon-terrain:before {
    content: "\f155";
}
.icon-teams-group:before {
    content: "\f156";
}
.icon-team:before {
    content: "\f157";
}
.icon-tasks-and-events:before {
    content: "\f158";
}
.icon-target:before {
    content: "\f159";
}
.icon-tag:before {
    content: "\f15a";
}
.icon-tag-with-subtags:before {
    content: "\f15b";
}
.icon-table-view-fullscreen:before {
    content: "\f15c";
}
.icon-table-database:before {
    content: "\f15d";
}
.icon-swiss-franc:before {
    content: "\f15e";
}
.icon-swap:before {
    content: "\f15f";
}
.icon-sun-light:before {
    content: "\f160";
}
.icon-summary:before {
    content: "\f161";
}
.icon-subpages:before {
    content: "\f162";
}
.icon-street:before {
    content: "\f163";
}
.icon-star:before {
    content: "\f164";
}
.icon-star-full:before {
    content: "\f165";
}
.icon-source:before {
    content: "\f166";
}
.icon-sort:before {
    content: "\f167";
}
.icon-smiley:before {
    content: "\f168";
}
.icon-smartphone:before {
    content: "\f169";
}
.icon-small-card-view-fullscreen:before {
    content: "\f16a";
}
.icon-sitemap:before {
    content: "\f16b";
}
.icon-sidebar:before {
    content: "\f16c";
}
.icon-shortcut:before {
    content: "\f16d";
}
.icon-share:before {
    content: "\f16e";
}
.icon-settings:before {
    content: "\f16f";
}
.icon-settings-clicked:before {
    content: "\f170";
}
.icon-set-time:before {
    content: "\f171";
}
.icon-selected-small:before {
    content: "\f172";
}
.icon-select2:before {
    content: "\f173";
}
.icon-select:before {
    content: "\f174";
}
.icon-search:before {
    content: "\f175";
}
.icon-search-view-snippet:before {
    content: "\f176";
}
.icon-search-view-list:before {
    content: "\f177";
}
.icon-search-view-card:before {
    content: "\f178";
}
.icon-search-list-standalone:before {
    content: "\f179";
}
.icon-search-empty:before {
    content: "\f17a";
}
.icon-satellite:before {
    content: "\f17b";
}
.icon-rotate-right:before {
    content: "\f17c";
}
.icon-rotate-left:before {
    content: "\f17d";
}
.icon-robot:before {
    content: "\f17e";
}
.icon-rich-bot:before {
    content: "\f17f";
}
.icon-rich-bot-question:before {
    content: "\f180";
}
.icon-rich-bot-happy:before {
    content: "\f181";
}
.icon-rich-bot-dead:before {
    content: "\f182";
}
.icon-restore-trash:before {
    content: "\f183";
}
.icon-reply:before {
    content: "\f184";
}
.icon-remove-link:before {
    content: "\f185";
}
.icon-reload:before {
    content: "\f186";
}
.icon-reach:before {
    content: "\f187";
}
.icon-questionmark:before {
    content: "\f188";
}
.icon-questionmark-circle:before {
    content: "\f189";
}
.icon-puzzle-slash:before {
    content: "\f18a";
}
.icon-puzzle-outline:before {
    content: "\f18b";
}
.icon-puzzle-outline-striked:before {
    content: "\f18c";
}
.icon-profile-circle:before {
    content: "\f18d";
}
.icon-profession:before {
    content: "\f18e";
}
.icon-print:before {
    content: "\f18f";
}
.icon-preview:before {
    content: "\f190";
}
.icon-preview-dark:before {
    content: "\f191";
}
.icon-preview-content:before {
    content: "\f192";
}
.icon-plus:before {
    content: "\f193";
}
.icon-plus-bigger:before {
    content: "\f194";
}
.icon-plus-add-square:before {
    content: "\f195";
}
.icon-plus-add-circle:before {
    content: "\f196";
}
.icon-play:before {
    content: "\f197";
}
.icon-play-video-slash:before {
    content: "\f198";
}
.icon-play-strike:before {
    content: "\f199";
}
.icon-play-circle:before {
    content: "\f19a";
}
.icon-pin-unclicked:before {
    content: "\f19b";
}
.icon-pin-clicked:before {
    content: "\f19c";
}
.icon-phone:before {
    content: "\f19d";
}
.icon-phone-contacts:before {
    content: "\f19e";
}
.icon-percent:before {
    content: "\f19f";
}
.icon-participant:before {
    content: "\f1a0";
}
.icon-palette:before {
    content: "\f1a1";
}
.icon-overview:before {
    content: "\f1a2";
}
.icon-options:before {
    content: "\f1a3";
}
.icon-operator-not-value:before {
    content: "\f1a4";
}
.icon-operator-not-equal:before {
    content: "\f1a5";
}
.icon-operator-not-contains:before {
    content: "\f1a6";
}
.icon-operator-not-contains-some:before {
    content: "\f1a7";
}
.icon-operator-lte:before {
    content: "\f1a8";
}
.icon-operator-lt:before {
    content: "\f1a9";
}
.icon-operator-in-between:before {
    content: "\f1aa";
}
.icon-operator-gte:before {
    content: "\f1ab";
}
.icon-operator-gt:before {
    content: "\f1ac";
}
.icon-operator-equal:before {
    content: "\f1ad";
}
.icon-operator-contains:before {
    content: "\f1ae";
}
.icon-operator-contains-some:before {
    content: "\f1af";
}
.icon-operator-any-value:before {
    content: "\f1b0";
}
.icon-open-website1:before {
    content: "\f1b1";
}
.icon-open-website:before {
    content: "\f1b2";
}
.icon-number:before {
    content: "\f1b3";
}
.icon-notifications:before {
    content: "\f1b4";
}
.icon-notification-sound:before {
    content: "\f1b5";
}
.icon-note:before {
    content: "\f1b6";
}
.icon-note-2:before {
    content: "\f1b7";
}
.icon-node-types:before {
    content: "\f1b8";
}
.icon-node-label:before {
    content: "\f1b9";
}
.icon-node-label-off:before {
    content: "\f1ba";
}
.icon-no-see:before {
    content: "\f1bb";
}
.icon-no-see-dark:before {
    content: "\f1bc";
}
.icon-no-relationships-unassigned:before {
    content: "\f1bd";
}
.icon-news:before {
    content: "\f1be";
}
.icon-multi-select:before {
    content: "\f1bf";
}
.icon-more-options:before {
    content: "\f1c0";
}
.icon-more-options-vertical:before {
    content: "\f1c1";
}
.icon-moon-dark:before {
    content: "\f1c2";
}
.icon-minus:before {
    content: "\f1c3";
}
.icon-millimeter:before {
    content: "\f1c4";
}
.icon-milligram:before {
    content: "\f1c5";
}
.icon-meter:before {
    content: "\f1c6";
}
.icon-merge:before {
    content: "\f1c7";
}
.icon-menu-book:before {
    content: "\f1c8";
}
.icon-mention-card:before {
    content: "\f1c9";
}
.icon-mention-at:before {
    content: "\f1ca";
}
.icon-mention-at-domain:before {
    content: "\f1cb";
}
.icon-memory-archive-folder:before {
    content: "\f1cc";
}
.icon-media-strike:before {
    content: "\f1cd";
}
.icon-medal:before {
    content: "\f1ce";
}
.icon-marker:before {
    content: "\f1cf";
}
.icon-marker-filled:before {
    content: "\f1d0";
}
.icon-map:before {
    content: "\f1d1";
}
.icon-map-view:before {
    content: "\f1d2";
}
.icon-manager:before {
    content: "\f1d3";
}
.icon-magic-wand:before {
    content: "\f1d4";
}
.icon-login:before {
    content: "\f1d5";
}
.icon-log-out:before {
    content: "\f1d6";
}
.icon-lock:before {
    content: "\f1d7";
}
.icon-lock-light:before {
    content: "\f1d8";
}
.icon-location:before {
    content: "\f1d9";
}
.icon-loading:before {
    content: "\f1da";
}
.icon-loading-time:before {
    content: "\f1db";
}
.icon-loading-bubbles:before {
    content: "\f1dc";
}
.icon-list-view:before {
    content: "\f1dd";
}
.icon-list-number:before {
    content: "\f1de";
}
.icon-list-checkbox:before {
    content: "\f1df";
}
.icon-list-bullet:before {
    content: "\f1e0";
}
.icon-link:before {
    content: "\f1e1";
}
.icon-link-add:before {
    content: "\f1e2";
}
.icon-like:before {
    content: "\f1e3";
}
.icon-light:before {
    content: "\f1e4";
}
.icon-levels:before {
    content: "\f1e5";
}
.icon-length:before {
    content: "\f1e6";
}
.icon-label-tag:before {
    content: "\f1e7";
}
.icon-label-list:before {
    content: "\f1e8";
}
.icon-label-card:before {
    content: "\f1e9";
}
.icon-knowledge-network:before {
    content: "\f1ea";
}
.icon-kilometer:before {
    content: "\f1eb";
}
.icon-kilogram:before {
    content: "\f1ec";
}
.icon-keyword:before {
    content: "\f1ed";
}
.icon-keyboard-shift:before {
    content: "\f1ee";
}
.icon-keyboard-mac-option:before {
    content: "\f1ef";
}
.icon-keyboard-mac-control:before {
    content: "\f1f0";
}
.icon-keyboard-enter:before {
    content: "\f1f1";
}
.icon-keyboard-command:before {
    content: "\f1f2";
}
.icon-key1:before {
    content: "\f1f3";
}
.icon-key:before {
    content: "\f1f4";
}
.icon-key-password:before {
    content: "\f1f5";
}
.icon-jpy:before {
    content: "\f1f6";
}
.icon-interests:before {
    content: "\f1f7";
}
.icon-integrations:before {
    content: "\f1f8";
}
.icon-info:before {
    content: "\f1f9";
}
.icon-info-dark:before {
    content: "\f1fa";
}
.icon-info-book:before {
    content: "\f1fb";
}
.icon-importance:before {
    content: "\f1fc";
}
.icon-import:before {
    content: "\f1fd";
}
.icon-image-cover:before {
    content: "\f1fe";
}
.icon-icon-volume-muted:before {
    content: "\f1ff";
}
.icon-icon-contact-empty:before {
    content: "\f200";
}
.icon-home:before {
    content: "\f201";
}
.icon-heart:before {
    content: "\f202";
}
.icon-graph-view-network:before {
    content: "\f203";
}
.icon-gram:before {
    content: "\f204";
}
.icon-gift-present:before {
    content: "\f205";
}
.icon-geolocation:before {
    content: "\f206";
}
.icon-gbp:before {
    content: "\f207";
}
.icon-funnel:before {
    content: "\f208";
}
.icon-funnel-dark:before {
    content: "\f209";
}
.icon-fullscreen-outside:before {
    content: "\f20a";
}
.icon-fullscreen-inside:before {
    content: "\f20b";
}
.icon-format-italic:before {
    content: "\f20c";
}
.icon-format-card-view:before {
    content: "\f20d";
}
.icon-format-bold:before {
    content: "\f20e";
}
.icon-format-align-right:before {
    content: "\f20f";
}
.icon-format-align-left:before {
    content: "\f210";
}
.icon-format-align-justify:before {
    content: "\f211";
}
.icon-format-align-center:before {
    content: "\f212";
}
.icon-font-color:before {
    content: "\f213";
}
.icon-folder:before {
    content: "\f214";
}
.icon-focus-mode:before {
    content: "\f215";
}
.icon-flash-bolt:before {
    content: "\f216";
}
.icon-flag:before {
    content: "\f217";
}
.icon-flag-dark:before {
    content: "\f218";
}
.icon-filter:before {
    content: "\f219";
}
.icon-file-preview:before {
    content: "\f21a";
}
.icon-feedback-camera:before {
    content: "\f21b";
}
.icon-eye-hidden:before {
    content: "\f21c";
}
.icon-extend:before {
    content: "\f21d";
}
.icon-export:before {
    content: "\f21e";
}
.icon-experience:before {
    content: "\f21f";
}
.icon-evernote-notebook:before {
    content: "\f220";
}
.icon-euro:before {
    content: "\f221";
}
.icon-envelope:before {
    content: "\f222";
}
.icon-empty-trash:before {
    content: "\f223";
}
.icon-empty-trash-dark:before {
    content: "\f224";
}
.icon-emoji-happy:before {
    content: "\f225";
}
.icon-email:before {
    content: "\f226";
}
.icon-editor:before {
    content: "\f227";
}
.icon-edit:before {
    content: "\f228";
}
.icon-duplicate:before {
    content: "\f229";
}
.icon-droplet:before {
    content: "\f22a";
}
.icon-drag-and-drop:before {
    content: "\f22b";
}
.icon-draft:before {
    content: "\f22c";
}
.icon-download:before {
    content: "\f22d";
}
.icon-download-light:before {
    content: "\f22e";
}
.icon-download-dark:before {
    content: "\f22f";
}
.icon-download-cloud:before {
    content: "\f230";
}
.icon-double-chevron:before {
    content: "\f231";
}
.icon-dollar:before {
    content: "\f232";
}
.icon-document:before {
    content: "\f233";
}
.icon-dislike:before {
    content: "\f234";
}
.icon-diamond-normal:before {
    content: "\f235";
}
.icon-diamond-dark:before {
    content: "\f236";
}
.icon-diagram-join:before {
    content: "\f237";
}
.icon-diagram-exclude:before {
    content: "\f238";
}
.icon-diagram-common:before {
    content: "\f239";
}
.icon-degree-of-separation:before {
    content: "\f23a";
}
.icon-day-calendar-date:before {
    content: "\f23b";
}
.icon-date:before {
    content: "\f23c";
}
.icon-dashboard:before {
    content: "\f23d";
}
.icon-currency:before {
    content: "\f23e";
}
.icon-crown:before {
    content: "\f23f";
}
.icon-cross:before {
    content: "\f240";
}
.icon-cross-empty:before {
    content: "\f241";
}
.icon-cross-close:before {
    content: "\f242";
}
.icon-cross-circle:before {
    content: "\f243";
}
.icon-copy:before {
    content: "\f244";
}
.icon-contact:before {
    content: "\f245";
}
.icon-compress:before {
    content: "\f246";
}
.icon-company:before {
    content: "\f247";
}
.icon-comments:before {
    content: "\f248";
}
.icon-comment:before {
    content: "\f249";
}
.icon-comment-remove-outline:before {
    content: "\f24a";
}
.icon-comment-post:before {
    content: "\f24b";
}
.icon-columns:before {
    content: "\f24c";
}
.icon-color:before {
    content: "\f24d";
}
.icon-color-light:before {
    content: "\f24e";
}
.icon-color-dark:before {
    content: "\f24f";
}
.icon-coin:before {
    content: "\f250";
}
.icon-cluster:before {
    content: "\f251";
}
.icon-clock:before {
    content: "\f252";
}
.icon-circle-radiobutton-selected:before {
    content: "\f253";
}
.icon-chf:before {
    content: "\f254";
}
.icon-chevron:before {
    content: "\f255";
}
.icon-chevron-large:before {
    content: "\f256";
}
.icon-chevron-down-circle:before {
    content: "\f257";
}
.icon-chevron-big:before {
    content: "\f258";
}
.icon-chats-square:before {
    content: "\f259";
}
.icon-chats-search-chat:before {
    content: "\f25a";
}
.icon-chats-node-chat:before {
    content: "\f25b";
}
.icon-chats-monthly-messages:before {
    content: "\f25c";
}
.icon-chats-clear-chat:before {
    content: "\f25d";
}
.icon-chats-add-new-conversation:before {
    content: "\f25e";
}
.icon-chat:before {
    content: "\f25f";
}
.icon-chat-forum:before {
    content: "\f260";
}
.icon-chat-blank:before {
    content: "\f261";
}
.icon-centimeter:before {
    content: "\f262";
}
.icon-card-view:before {
    content: "\f263";
}
.icon-card-view-visualizer-standalone:before {
    content: "\f264";
}
.icon-card-view-snippet-standalone:before {
    content: "\f265";
}
.icon-card-view-location-standalone:before {
    content: "\f266";
}
.icon-card-view-location-standalone-2:before {
    content: "\f267";
}
.icon-card-view-large-image-standalone:before {
    content: "\f268";
}
.icon-card-view-calendar-standalone:before {
    content: "\f269";
}
.icon-camera:before {
    content: "\f26a";
}
.icon-call-out:before {
    content: "\f26b";
}
.icon-bus:before {
    content: "\f26c";
}
.icon-burguer-menu:before {
    content: "\f26d";
}
.icon-bug:before {
    content: "\f26e";
}
.icon-brain:before {
    content: "\f26f";
}
.icon-bolt:before {
    content: "\f270";
}
.icon-bold-unpin:before {
    content: "\f271";
}
.icon-bold-tick-square:before {
    content: "\f272";
}
.icon-bold-tick-circle:before {
    content: "\f273";
}
.icon-bold-send:before {
    content: "\f274";
}
.icon-bold-plus-add-circle:before {
    content: "\f275";
}
.icon-bold-play-media:before {
    content: "\f276";
}
.icon-bold-pin-fix:before {
    content: "\f277";
}
.icon-bold-pause-media:before {
    content: "\f278";
}
.icon-bold-minus-circle:before {
    content: "\f279";
}
.icon-bold-location:before {
    content: "\f27a";
}
.icon-bold-like:before {
    content: "\f27b";
}
.icon-bold-forward-media:before {
    content: "\f27c";
}
.icon-bold-filter-funnel:before {
    content: "\f27d";
}
.icon-bold-edit:before {
    content: "\f27e";
}
.icon-bold-dot-notification:before {
    content: "\f27f";
}
.icon-bold-dislike:before {
    content: "\f280";
}
.icon-bold-chats-help-message-question:before {
    content: "\f281";
}
.icon-bold-bolt:before {
    content: "\f282";
}
.icon-bold-backward-media:before {
    content: "\f283";
}
.icon-board-view-fullscreen:before {
    content: "\f284";
}
.icon-bill-money:before {
    content: "\f285";
}
.icon-big-image-standalone:before {
    content: "\f286";
}
.icon-bell-ring:before {
    content: "\f287";
}
.icon-backlog:before {
    content: "\f288";
}
.icon-back-in-time:before {
    content: "\f289";
}
.icon-automatic-search:before {
    content: "\f28a";
}
.icon-auto-mode:before {
    content: "\f28b";
}
.icon-attachment:before {
    content: "\f28c";
}
.icon-arrow:before {
    content: "\f28d";
}
.icon-arrow-right:before {
    content: "\f28e";
}
.icon-archive:before {
    content: "\f28f";
}
.icon-album:before {
    content: "\f290";
}
.icon-alarm:before {
    content: "\f291";
}
.icon-add-person:before {
    content: "\f292";
}
.icon-add-node:before {
    content: "\f293";
}
.icon-add-new-property:before {
    content: "\f294";
}
.icon-add-dark:before {
    content: "\f295";
}
